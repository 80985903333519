define("client/components/react-component/component", ["exports", "react-dom", "react-redux", "client/react/store", "@mui/material/styles", "notistack", "client/react/utilities/message-queue", "client/react/utilities/close-date-checker", "@mui/x-date-pickers-pro", "@mui/x-date-pickers-pro/AdapterMoment", "react-router-dom", "@mui/material", "react-dnd", "react-dnd-html5-backend"], function (_exports, _reactDom, _reactRedux, _store, _styles, _notistack, _messageQueue, _closeDateChecker, _xDatePickersPro, _AdapterMoment, _reactRouterDom, _material, _reactDnd, _reactDndHtml5Backend) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var theme = (0, _styles.createTheme)({
    palette: {
      primary: {
        main: "#2196F3"
      }
    },
    typography: {
      fontSize: 20
    },
    components: {
      MuiInputBase: {
        defaultProps: {
          disableInjectingGlobalStyles: true
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
          inputProps: {
            autoComplete: 'new-password'
          }
        },
        styleOverrides: {
          root: {
            marginBottom: 24,
            "& .MuiFormHelperText-root": {
              marginTop: 0,
              height: 0
            }
          }
        }
      },
      MuiSelect: {
        defaultProps: {
          variant: 'standard'
        }
      }
    }
  });

  var _default = Ember.Component.extend({
    layout: '',
    classNames: ['layout-column'],
    reactRender: function reactRender(reactComponent) {
      _reactDom.default.render(React.createElement(_styles.StyledEngineProvider, {
        injectFirst: true
      }, React.createElement(_styles.ThemeProvider, {
        theme: theme
      }, React.createElement(_material.CssBaseline, null), React.createElement(_notistack.SnackbarProvider, {
        autoHideDuration: 3000,
        maxSnack: 6,
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom'
        },
        TransitionProps: {
          direction: 'up'
        }
      }, React.createElement(_reactRedux.Provider, {
        store: _store.store
      }, React.createElement(_messageQueue.MessageQueueProvider, {
        url: "wss://rabbitmq2.servisuite.com:15671/ws"
      }, React.createElement(_xDatePickersPro.LocalizationProvider, {
        dateAdapter: _AdapterMoment.AdapterMoment
      }, React.createElement(_closeDateChecker.CloseDateCheckProvider, null, React.createElement(_reactDnd.DndProvider, {
        backend: _reactDndHtml5Backend.HTML5Backend
      }, React.createElement(_reactRouterDom.HashRouter, null, reactComponent))))))))), this.element);
    },
    unmountReactElement: function unmountReactElement() {
      _reactDom.default.unmountComponentAtNode(this.element);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.unmountReactElement();
    }
  });

  _exports.default = _default;
});